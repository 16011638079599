<template>
  <div>
    <h1 class="heading-txt mb-3">Account overview</h1>
    <UpgradePlanWidget
      v-if="user.subscription && user.subscription.plan.name.toLowerCase() === 'melior start' || user.subscription && user.subscription.plan.name.toLowerCase() === ''" />

    <div class="row">
      <div class="col-md-4">
        <div class="card siteCard mt-3" v-if="user.subscription && user.subscription.plan">
          <div class="p-3">
            <p class="active-plan">{{ user.subscription?.status | capitalize }} plan</p>
            <h2 class="text-dark font-weight-bold">
              {{ user.subscription.plan.name }}
            </h2>
           
            <p class="text-muted text-sm" v-if="user.subscription?.status === 'active'">
              Expires
              {{ moment(new Date(user.subscription.end_date)).fromNow() }}
            </p>
            <p class="text-dark text-sm">{{ user.subscription.plan.description }}</p>

            <div class="mt-6">
              <router-link to="/upgrade-plan" class="btn btn-primary btn-site px-5">
                <span v-if="user.subscription.amount == 0"> Join Premium</span>
                <span v-else> Upgrade plans</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card siteCard mt-3">
          <div class="p-3">
            <div class="row mb-4">
              <div class="col-md-6 sect-data pb-2">
                <p class="creatorTitle mb-2">Name</p>
                <h6 class="text-dark">{{ user.name }}</h6>
              </div>
              <div class="col-md-6 sect-data pb-2">
                <p class="creatorTitle mb-2">Email</p>
                <h6 class="text-dark">{{ user.email }}</h6>
              </div>
              <div class="col-md-6 sect-data pb-2">
                <p class="creatorTitle mb-2">Artist name</p>
                <h6 class="text-dark">{{ user.artist_name }}</h6>
              </div>
              <div class="col-md-6 sect-data pb-2">
                <p class="creatorTitle mb-2">Phone</p>
                <h6 class="text-dark">{{ user.phone }}</h6>
              </div>
              <div class="col-md-6 sect-data pb-2">
                <p class="creatorTitle mb-2">Phone</p>
                <h6 class="text-dark">{{ user.phone }}</h6>
              </div>
              <div class="col-md-6 sect-data pb-2">
                <p class="creatorTitle mb-2">Country</p>
                <h6 class="text-dark">{{ user.country }}</h6>
              </div>
            </div>
            <router-link to="/edit-profile" class="btn btn-primary btn-site px-5">
              <span> Edit Profile</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UpgradePlanWidget from "@/components/UpgradePlanWidget";
export default {
  components: { UpgradePlanWidget },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.sect-banner {
  background: #240f0c;
  padding: 28px;
  position: relative;
  overflow: hidden;

  .img-preview {
    width: 20em;
    position: absolute;
    right: 89px;
    top: 0%;
  }
}

.heading-txt {
  font-size: 3em;
  line-height: 1;
  font-weight: 700;
  color: #000;
}

.sect-data {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 13px;
}

.active-plan {
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
</style>
